<template>
  <div class="basic">
    <!-- 基本信息 -->
    <div class="title">供应商信息</div>
    <div class="form">
      <el-form ref="ruleForm" :model="form" :rules="rules" label-width="130px" label-position="right" style="width: 100%">
        <el-form-item label="营业执照" prop="busiLicense">
          <el-image class="img" :src="form.busiLicense" fit="cover" :preview-src-list="[form.busiLicense]"></el-image>
        </el-form-item>
        <el-form-item label="供应商名称" prop="supplierName">
          <el-input v-model.trim="form.supplierName" disabled></el-input>
        </el-form-item>
        <el-form-item label="统一社会信用代码" prop="creditCode">
          <el-input v-model="form.creditCode" disabled></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contacts">
          <el-input v-model="form.contacts" placeholder="请输入联系人"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="telphone">
          <el-input v-model.number="form.telphone" type="number" placeholder="请输入联系电话"></el-input>
        </el-form-item>
        <el-form-item label="Logo" prop="logoPic" class="logo_pic">
          <el-upload class="upload_demo" accept=".png,.jpeg,.jpg" :file-list="fileList" :limit="1" name="multipartFile" :action="action" list-type="picture" :show-file-list="false" :disabled="form.logoPic ? true : false" :on-success="handleSuccess" :before-upload="beforeUploads" :on-remove="handleRemove" :on-preview="handlePreview">
            <el-image v-if="form.logoPic" class="logo_img" :src="form.logoPic" fit="cover"></el-image>
            <i v-if="!form.logoPic" class="el-icon-plus"></i>
          </el-upload>
          <i v-if="form.logoPic" class="delpic el-icon-error" @click="handleRemove"></i>
        </el-form-item>
        <!-- <el-form-item label="供应商地址" prop="">
          <el-input v-model="form.local" disabled></el-input>
        </el-form-item> -->
        <el-form-item label="所在地区" prop="local">
          <el-cascader class="cascader" ref="cascader" v-model="form.local" :options="options" placeholder="请选择地区" :props="{ value: 'code', label: 'name' }" style="display: block"></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input v-model="form.address" placeholder="请输入详细地址"></el-input>
        </el-form-item>
        <el-form-item label="供应商简介" prop="supplierIntroduce">
          <el-input type="textarea" v-model="form.supplierIntroduce" placeholder="请输入供应商简介" :rows="4" show-word-limit></el-input>
        </el-form-item>
        <el-divider></el-divider>
        <div class="title">财务信息</div>
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="财务联系人" prop="financeName">
              <el-input v-model="form.financeName" placeholder="请输入财务联系人"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="财务联系人电话" prop="financePhone">
              <el-input v-model.number="form.financePhone" type="number" placeholder="请输入财务联系人电话"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="企业支付宝账号" prop="zfbAccount">
              <el-input v-model="form.zfbAccount" placeholder="请输入企业支付宝账号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="真实姓名" prop="realName">
              <el-input v-model="form.realName" placeholder="请输入真实姓名"></el-input>
            </el-form-item>
          </el-col>
        </el-row> -->
        <div class="title">收款账户信息</div>
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="开户行" prop="financeBankDto.openBank">
              <el-input v-model="form.financeBankDto.openBank" placeholder="请输入开户行"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="户名" prop="financeBankDto.accountName">
              <el-input v-model="form.financeBankDto.accountName" placeholder="请输入户名"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="账号" prop="financeBankDto.accountNum">
              <el-input v-model="form.financeBankDto.accountNum" type="number" placeholder="请输入账号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联行号" prop="financeBankDto.interbankNum">
              <el-input v-model="form.financeBankDto.interbankNum" placeholder="请输入联行号"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="submit">
          <el-button type="primary" @click="handleSumbit('ruleForm')">保存</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
// 表单初始化
function createdForm() {
  return {
    address: "",
    provinceCode: "",
    province: "",
    cityCode: "",
    city: "",
    areaCode: "",
    area: "",
    local: [],
    supplierName: "", // 供应商名称
    supplierIntroduce: "", // 简介
    contacts: "", // 联系人
    telphone: "", // 联系电话
    financeName: "", // 财务负责人
    financePhone: "", // 财务负责人电话
    logoPic: "", // logo
    logoPicId: "", // logoid
    busiLicense: "", // 营业执照
    busiLicenseId: "", // 营业执照id
    creditCode: "", // 统一社会信用代码
    financeBankDto: {
      openBank: "", // 开户行
      accountName: "", // 户名
      accountNum: "", // 账号
      interbankNum: "", // 联行号
      id: "",
      bizType: 2, // 类型：1商超；2供应商；3总后台
    },
    // bizType: 2, // 类型：1商超；2供应商；3总后台
    // zfbAccount: "", // 支付宝账号
    // realName: "", // 真实姓名
  }
}
export default {
  data() {
    return {
      iconLoading: false,
      form: createdForm(),
      action: this.$axios.defaults.baseURL + this.$api.uploadFile,
      fileList: [],
      options: [],
      showDel: false,
      rules: {
        address: [{ required: true, message: "请输入详细地址", trigger: "blur" }],
        local: [{ required: true, message: "请选择地区", trigger: "change" }],
        telphone: [
          { required: true, message: "请填写手机号码", trigger: "blur" },
          { pattern: /^1[345789]\d{9}$/, message: "请填写正确手机号码", trigger: "blur" },
        ],
        financeName: { required: true, message: "请填写财务联系人", trigger: "blur" },
        financePhone: { required: true, message: "请填写财务联系人电话", trigger: "blur" },
        contacts: { required: true, message: "请填写联系人", trigger: "blur" },
        supplierIntroduce: { required: true, message: "请填写供应商简介", trigger: "blur" },
        "financeBankDto.openBank": { required: true, message: "请填写开户行", trigger: "blur" },
        "financeBankDto.accountName": { required: true, message: "请填写户名", trigger: "blur" },
        "financeBankDto.accountNum": { required: true, message: "请填写账号", trigger: "blur" },
        "financeBankDto.interbankNum": { required: true, message: "请填写联行号", trigger: "blur" },
      },
    }
  },
  created() {
    this.getMySuppliers()
    this.getAreas()
  },
  methods: {
    getMySuppliers() {
      this.$axios.get(this.$api.getMySupplier).then(res => {
        const { code, result } = res.data
        if (code == 100) {
          this.form = Object.assign({}, result)
          this.form.local = [result.provinceCode, result.cityCode, result.areaCode]
        } else {
          this.$message({ showClose: true, message: res.data.desc, type: "error" })
        }
      })
    },
    getAreas() {
      this.$axios.get(this.$api.getDistrictInfo).then(res => {
        const { code, result } = res.data
        if (code == 100) {
          this.options = result
        }
      })
    },
    // 文件上传
    beforeUploads(file) {
      const isJPG = file.type === "image/jpeg" || "image/jpg" || "image/png"
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isJPG) {
        this.$message.error("图文只支持jpg、png、jpeg，请调整格式再上传!")
      }
      if (!isLt5M) {
        this.$message.error("上传图片大小不能超过 5MB!")
      }
      return isJPG && isLt5M
    },
    handlePreview() {},
    // 删除文件
    handleRemove() {
      this.form.logoPic = ""
      this.form.logoPicId = ""
      this.fileList = []
    },
    // 上传成功
    handleSuccess(res) {
      if (res.code == 100) {
        this.form.logoPicId = res.result.fileId
        this.form.logoPic = res.result.fileUrl
      } else {
        this.$message({ showClose: true, message: res.desc, type: "error" })
      }
    },
    // 保存
    handleSumbit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$axios
            .put(this.$api.updateMySupplier, {
              supplierName: this.form.supplierName, // 供应商名称
              logoPic: this.form.logoPicId, // logo
              supplierIntroduce: this.form.supplierIntroduce, // 简介
              supplierId: this.$store.state.userInfo.supplierId, //供应商id
              contacts: this.form.contacts, // 联系人
              telphone: this.form.telphone, // 联系电话
              financeName: this.form.financeName, // 财务负责人
              financePhone: this.form.financePhone, // 财务负责人电话
              busiLicense: this.form.busiLicenseId, // 营业执照
              creditCode: this.form.creditCode, // 统一社会信用代码
              // zfbAccount: this.form.zfbAccount, // 支付宝账号
              // realName: this.form.realName, // 真实姓名
              financeBankDto: {
                openBank: this.form.financeBankDto.openBank, // 开户行
                accountName: this.form.financeBankDto.accountName, // 户名
                accountNum: this.form.financeBankDto.accountNum, // 账号
                interbankNum: this.form.financeBankDto.interbankNum, // 联行号
                // bizType: this.form.financeBankDto.bizType, // 类型：1商超；2供应商；3总后台
                bizType: 2, //后端要求传
                id: this.form.financeBankDto.id, // 类型：1商超；2供应商；3总后台
                supplierId: this.$store.state.userInfo.supplierId, //供应商id
              },
              provinceCode: (this.form.provinceCode = this.$refs["cascader"].getCheckedNodes()[0].path[0]),
              cityCode: (this.form.cityCode = this.$refs["cascader"].getCheckedNodes()[0].path[1]),
              areaCode: (this.form.areaCode = this.$refs["cascader"].getCheckedNodes()[0].path[2]),
              province: (this.form.province = this.$refs["cascader"].getCheckedNodes()[0].pathLabels[0]),
              city: (this.form.city = this.$refs["cascader"].getCheckedNodes()[0].pathLabels[1]),
              area: (this.form.area = this.$refs["cascader"].getCheckedNodes()[0].pathLabels[2]),
              address: this.form.address,
            })
            .then(res => {
              if (res.data.code == 100) {
                this.$message.success("已保存")
                this.fileList = []
                this.getMySuppliers()
              } else {
                this.$message({ showClose: true, message: res.data.desc, type: "error" })
              }
            })
        } else {
          this.submitLoding = false
          this.$message.error("提交失败")
          return false
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.basic {
  padding: 30px;
  box-sizing: border-box;

  .title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin: 30px 0;
  }

  .form {
    display: flex;
    width: 750px;
    margin: 0 auto;

    .img {
      width: 100px;
      height: 100px;
      border-radius: 6px;
    }

    .upload_demo {
      width: 100px;
      height: 100px;

      /deep/ .el-upload {
        position: relative;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
      }

      /deep/ .el-upload:hover {
        border-color: #069bbc;
      }

      .el-icon-plus {
        font-size: 28px;
        color: #8c939d;
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
      }

      /deep/.logo_img {
        display: block;
        width: 100px;
        height: 100px;
        border-radius: 6px;
      }
    }

    .logo_pic {
      /deep/.el-form-item__content {
        width: 100px;
      }

      .delpic {
        position: absolute;
        top: -12px;
        right: -15px;
        color: red;
        font-size: 20px;
        cursor: pointer;
      }
    }

    /deep/.el-textarea__inner,
    /deep/.el-input__count {
      border: none;
      background-color: #f2f2f2;
    }

    .submit {
      text-align: center;

      margin-top: 40px;

      .el-button {
        width: 100px;
      }
    }
  }
  .cascader {
    width: 100%;
    ::v-deep .el-input,
    ::v-deep .el-input__inner {
      width: 100%;
    }
  }
}
</style>
